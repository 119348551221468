<template>
  <common-trs-page-container>
    <v-progress-linear
      v-if="loading"
      color="primary"
      indeterminate
    />
    <div
      v-if="holdersInvestments && holdersInvestments.length > 0"
    >
      <common-app-page-header-v2
        title="Holdings"
        :padding="0"
        :has-back-button="true"
      />
      <common-trs-data-table
        :headers="headers"
        :items="holdersInvestments"
        :loading="loading"
        :items-per-page="itemsPerPage"
        hide-default-footer
      >
        <template v-slot:[`item.registration_name`]="{ item}">
          <router-link
            class="text-capitalize primary--text cursor-pointer"
            :to="{ name: 'Holder.HoldingsDetails', params: { id: item.id, type: securityType } }"
          >
            {{ item.registration_name }}
          </router-link>
        </template>
        <template v-slot:[`item.number`]="{ item}">
          {{ $number(item.number) }}
        </template>
        <template v-slot:[`item.granted_on`]="{ item}">
          {{ $date(item.granted_on) }}
        </template>
      </common-trs-data-table>
    </div>
  </common-trs-page-container>
</template>

<script>
  import * as captableService from '@/components/common/captable/services/captable'

  export default {
    name: 'HoldingsIndex',

    data: () => ({
      loading: false,
      headers: [
        { text: 'Registration name', value: 'registration_name', sortable: true },
        { text: 'Number of granted securities', value: 'number', sortable: true },
        { text: 'Granted On', value: 'granted_on', sortable: true },
      ],
      holdersInvestments: [],
      itemsPerPage: 10000000,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      securityTermId () {
        return this.$route.params.id
      },
      securityType () {
        return this.$route.params.type
      },
      holderId () {
        return this.$store.getters['auth/holder']?.id || this.$store.getters['auth/user']?.id
      },
    },

    watch: {
      securityType: {
        handler (val) {
          if (val) {
            this.loading = true
            this.loadDataByType()
          }
        },
        immediate: true,
      },
    },

    methods: {
      loadDataByType () {
        switch (this.securityType) {
          case this.CONSTS.SHARE_TERM_TYPES.COMMON:
          case this.CONSTS.SHARE_TERM_TYPES.PREFERRED:
            this.loadIssuedShares()
            break
          case this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE:
            this.loadIssuedConvertibles()
            break
          case this.CONSTS.SHARE_TERM_TYPES.OPTION:
            this.loadIssuedOptions()
            break
          case this.CONSTS.SHARE_TERM_TYPES.WARRANT:
            this.loadIssuedWarrants()
            break
        }
      },

      async loadIssuedShares () {
        try {
          const resp = await captableService.getIssuedShareList(this.captableId, true)
          if (resp && resp.data) {
            await this.filterHoldersData(resp.data.shares)
          }
        } catch (e) {}
        this.loading = false
      },
      async loadIssuedWarrants () {
        try {
          const resp = await captableService.getIssuedWarrantList(this.captableId, true)
          if (resp && resp.data) {
            await this.filterWarrantsHoldersData(resp.data.warrants)
          }
        } catch (e) {}
        this.loading = false
      },
      async loadIssuedConvertibles () {
        try {
          const resp = await captableService.getIssuedConvertibleList(this.captableId, true)
          if (resp && resp.data) {
            await this.filterConvertibleHoldersData(resp.data.convertibles)
          }
        } catch (e) {}
        this.loading = false
      },

      async loadIssuedOptions () {
        try {
          const resp = await captableService.getOptionsList(this.captableId, true)
          if (resp && resp.data) {
            await this.filterOptionsHoldersData(resp.data.options)
          }
        } catch (e) {}
        this.loading = false
      },
      filterHoldersData (shareList) {
        const shareTermInvestments = shareList?.filter(share => share.share_terms_id === this.securityTermId)
        this.holdersInvestments = shareTermInvestments?.filter(share => this.holderId.includes(share.holder_id))
        this.sortDataAsPerDate()
      },
      filterConvertibleHoldersData (shareList) {
        const shareTermInvestments = shareList?.filter(share => share.convertible_terms_id === this.securityTermId)
        this.holdersInvestments = shareTermInvestments?.filter(share => this.holderId.includes(share.holder_id))
        this.sortDataAsPerDate()
      },
      filterOptionsHoldersData (shareList) {
        const shareTermInvestments = shareList?.filter(share => share.equity_incentive_plan_id === this.securityTermId)
        this.holdersInvestments = shareTermInvestments?.filter(share => this.holderId.includes(share.holder_id))
        this.sortDataAsPerDate()
      },
      filterWarrantsHoldersData (shareList) {
        const shareTermInvestments = shareList?.filter(share => share.warrant_terms_id === this.securityTermId)
        this.holdersInvestments = shareTermInvestments?.filter(share => this.holderId.includes(share.holder_id))
        this.sortDataAsPerDate()
      },
      sortDataAsPerDate () {
        if (this.holdersInvestments && this.holdersInvestments.length > 0) {
          this.holdersInvestments.sort((a, b) => {
            return new Date(a?.granted_on) - new Date(b?.granted_on)
          })
        }
      },
    },
  }
</script>
